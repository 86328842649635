import Carousel from '@ui/Carousel';
import { getItems, logEvent } from '@util/analytics';
import useLogWhenInView from '@util/hooks/useLogWhenInView';
import { useRecentlyViewed } from '../hooks';
import { renderProductCards } from '../utils';
import HomeSection from './Home/components/HomeSection';

const RecentlyViewed = () => {
  const { recentlyViewedProducts } = useRecentlyViewed();

  useLogWhenInView(() => {
    if (recentlyViewedProducts && recentlyViewedProducts.length > 4) {
      logEvent('view_item_list', {
        item_list_name: 'Recently Viewed',
        item_list_id: 'recently_viewed',
        items: getItems(recentlyViewedProducts),
      });
    }
  });

  if (recentlyViewedProducts && recentlyViewedProducts.length > 5) {
    return (
      <HomeSection title="Recently Viewed">
        <Carousel
          loading={typeof recentlyViewedProducts === 'undefined'}
          items={renderProductCards(
            recentlyViewedProducts,
            {
              list_name: 'recently_viewed',
              list_id: 'recently_viewed',
            },
            {
              data_source: 'typesense',
              page: 'homepage',
              section: 'recently_viewed',
            }
          )}
        />
      </HomeSection>
    );
  }
  return null;
};

export default RecentlyViewed;

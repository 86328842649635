import Button from '@ui/Button';
import { prefix } from '@util/imagePrefix';
import FlashSales from 'app/sale/[curated-list]/_components/FlashSales';
import SellerList from 'app/sale/[curated-list]/_components/SellerList';
import { SalePageResponse } from 'app/sale/[curated-list]/page';
import { ChevronRight } from 'lucide-react';
import Image from 'next/image';

export default function HolidayDeals({ data }: { data: SalePageResponse }) {
  return (
    <div className="w-full bg-black pb-[5rem]">
      <div className="mx-auto hidden h-[50rem] flex-col items-center justify-center gap-[5rem] bg-holiday-deals-header bg-bottom object-bottom sm:block w-full" />

      <div className="mx-auto flex h-[40rem] flex-col items-center justify-center gap-[5rem] bg-mobile-holiday-deals-header bg-bottom sm:hidden">
        <div className="flex flex-col items-center gap-y-8">
          <Image
            src={`${prefix}/assets/holiday-deals_1920x2560.png`}
            alt="Holiday Deals"
            className="h-[10rem] w-auto"
            height={240}
            width={480}
          />

          <span className="text-[2rem] text-center w-[40rem] font-bold text-white">
            Discover thousands of deals from top brands this holiday season.
          </span>
        </div>
      </div>

      <FlashSales initialData={data.flashDeals} />

      <div className="mx-auto mt-8 flex w-full max-w-default flex-col items-center justify-center gap-[5rem]">
        <p className="text-[3rem] font-semibold text-white sm:text-[4rem]">
          STOREWIDE SALES
        </p>

        <SellerList stores={data.stores} />

        <Button
          trailingIcon={<ChevronRight />}
          type="secondary"
          text="Shop Entire Sale"
          href="/sale/holiday-deals"
        />
      </div>
    </div>
  );
}

'use client';

import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import useIntersectionObserver from '@util/hooks/useIntersectionObserver';
import { useEffect, useRef, useState } from 'react';
import FlashCard from './components/FlashCard';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ProductDocument } from 'models/product';
import Link from 'next/link';

type FlashDealsProps = {
  title: string;
  isFetching: boolean;
  allProducts: ProductDocument[];
};

export default function FlashDeals({
  title,
  isFetching,
  allProducts,
}: FlashDealsProps) {
  const INCREMENT_BY = 5;
  const FLEX_GAP = 16;
  const [currentIndex, setCurrent] = useState(0);

  const carouselfRef = useRef<HTMLDivElement>(null);
  const frontRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const frontEntry = useIntersectionObserver(frontRef, {});
  const endEntry = useIntersectionObserver(endRef, {});

  const handleLeftArrowClick = () => {
    setCurrent((prev) => Math.max(prev - INCREMENT_BY, 0));
  };

  const handleRightArrowClick = () =>
    setCurrent((prev) =>
      Math.min(prev + INCREMENT_BY, allProducts ? allProducts.length : 0)
    );

  useEffect(() => {
    if (carouselfRef.current && cardRef.current) {
      const cardWidth = currentIndex * cardRef.current.offsetWidth;
      const cardSpacing = currentIndex * FLEX_GAP;
      const offset = cardWidth + cardSpacing;
      carouselfRef.current.scrollLeft = offset;
    }
  }, [currentIndex]);

  return (
    <div className="flex w-full flex-col bg-black px-4 py-16 lg:p-16">
      <div className="w-full sm:mx-auto sm:max-w-[150rem]">
        <div className="flex w-full items-center justify-between gap-x-8 sm:max-w-content">
          <h4 className="pl-8 text-[3rem] font-semibold text-white lg:pl-0">
            {title ?? 'Flash Deals ⚡️'}
          </h4>

          <Link
            href="/shop/curated/flash-deals"
            className="text-[1.8rem] text-white"
          >
            See All
          </Link>
        </div>

        <div className="mt-8 sm:max-w-content">
          <div className="relative w-full">
            {allProducts && allProducts.length > 0 ? (
              <>
                {/* left arrow */}
                {!frontEntry?.isIntersecting && (
                  <div className="absolute -left-16 top-0 z-20 hidden h-full items-center sm:flex">
                    {/* <Arrow
                      variant="left"
                      onClick={() => handleLeftArrowClick()}
                    /> */}

                    <ChevronLeftIcon
                      className="h-14 w-14 cursor-pointer text-brand-white"
                      onClick={() => handleLeftArrowClick()}
                    />
                  </div>
                )}

                <div
                  className="no-scrollbar w-full overflow-x-scroll scroll-smooth px-4 pb-[1rem] sm:px-0 lg:overflow-x-hidden"
                  ref={carouselfRef}
                >
                  <div className="mobile-zoom-product-card-container flex w-min gap-x-[2rem] gap-y-[1.6rem] py-4 ">
                    {allProducts?.map((product, i) => {
                      // append extra div to front of first item for frontRef
                      if (i === 0) {
                        return (
                          <div key={i} className="flex">
                            <div ref={frontRef} />
                            <div ref={i === 0 ? cardRef : null}>
                              <FlashCard product={product} />
                            </div>
                          </div>
                        );
                      }
                      // append extra div to end of last item for endRef
                      if (i === allProducts.length - 1) {
                        return (
                          <div key={i} className="flex">
                            <div ref={i === 0 ? cardRef : null}>
                              <FlashCard product={product} />
                            </div>
                            <div ref={endRef} />
                          </div>
                        );
                      }
                      return (
                        <div key={i}>
                          <FlashCard product={product} />
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* right arrow */}
                {!endEntry?.isIntersecting && (
                  <div className="absolute -right-16 top-0 z-10 hidden h-full items-center sm:flex">
                    {/* <Arrow
                      variant="right"
                      onClick={() => handleRightArrowClick()}
                    /> */}

                    <ChevronRightIcon
                      className="h-14 w-14 cursor-pointer text-brand-white"
                      onClick={() => handleRightArrowClick()}
                    />
                  </div>
                )}
              </>
            ) : isFetching ? (
              <div className="flex gap-[1.6rem]">
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
                <ProductCardSkeleton />
              </div>
            ) : (
              <div className="flex gap-[1.6rem]">
                <h3>No flash deals found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

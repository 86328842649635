import { ProductDocument } from '@models/product';
import {
  uploadListingImage,
  uploadOtherImage,
  uploadReviewImage,
} from '@util/firestore/listing';
import { logError } from '@util/logError';
import { getCdnImageUrls } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { blobToBase64 } from '..';

/**
 *
 * React hook to handle multiple image uploads and deletions.
 * @param {object} props - The input properties for the hook.
 * @param {string} [props.productId] - The optional product ID for the images to be uploaded.
 * @param {(paths: ProductDocument['images']) => void} [props.onUploadCallback] - Optional callback to be executed when an image is uploaded.
 * @param {(paths: ProductDocument['images']) => void} [props.onDeleteCallback] - Optional callback to be executed when an image is deleted.
 **/
export default function useMultiImageUpload({
  id,
  onUploadCallback,
  onDeleteCallback,
  type = 'listing',
}: {
  id?: string;
  onUploadCallback?: (args: {
    paths: ProductDocument['images'];
    errors: string[];
  }) => void;
  onDeleteCallback?: (paths: ProductDocument['images']) => void;
  type: 'listing' | 'review' | 'other';
}) {
  const [uploadingImage, setUploadingImage] = useState(false);
  const { user } = useAuth();

  async function handleFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!user) return;
    // if file is not an image, skip
    if (!e.target.files?.[0].type.includes('image')) {
      console.error('Only images are allowed');
      return;
    }
    const images = e?.target?.files ?? [];
    if (images.length > 0) {
      const args = await uploadImages(Array.from(images));
      if (onUploadCallback) onUploadCallback(args);
    }
  }

  async function uploadImages(images: File[]) {
    if (!user) return { paths: [], errors: ['User not found'] };
    const paths: ProductDocument['images'] = [];
    const errors: string[] = [];

    try {
      setUploadingImage(true);

      const anyAreHeic = images.some((image) => image.type === 'image/heic');
      const heic2any = anyAreHeic ? (await import('heic2any')).default : null;

      const uploadPromises = images.map(async (image) => {
        let file = image;
        // If we want to support HEIC images on frontend, we can use this code. Currently backend does the conversion.
        if (image.type === 'image/heic' && heic2any) {
          try {
            const localUrl = URL.createObjectURL(image);
            const res = await fetch(localUrl);
            const blob = await res.blob();
            const converted = await heic2any({
              blob,
              toType: 'image/jpeg',
            });
            const data = new File([converted as Blob], image.name, {
              type: 'image/jpeg',
            });
            file = data;
          } catch (e) {
            logError(
              e,
              undefined,
              undefined,
              `HEIC conversion failed for ${image.name} ${(
                image.size /
                1024 /
                1024
              ).toFixed(2)}MB`
            );
            errors.push(`Invalid image format for ${image.name}`);
            return null;
          }
        }
        const base64 = await blobToBase64(file);
        if (type === 'listing') {
          const { download_url, remotePath } = await uploadListingImage({
            data: base64,
            productId: id ?? '',
            userId: user.uid,
          });
          return {
            download_url,
            ...getCdnImageUrls(remotePath),
          };
        } else if (type === 'review') {
          const { download_url, remotePath } = await uploadReviewImage(
            base64,
            id ?? ''
          );
          return {
            download_url,
            ...getCdnImageUrls(remotePath),
          };
        } else {
          const { download_url, remotePath } = await uploadOtherImage(
            base64,
            id ?? ''
          );
          return {
            download_url,
            ...getCdnImageUrls(remotePath),
          };
        }
      });

      const res = await Promise.allSettled(uploadPromises);
      res.forEach((r, i) => {
        if (r.status === 'rejected')
          logError(
            r.reason,
            undefined,
            undefined,
            `uploadImages: ${images[i].type} ${(
              images[i].size /
              1024 /
              1024
            ).toFixed(2)}MB failed`
          );
        else if (r.status === 'fulfilled' && r.value) paths.push(r.value);
      });
    } catch (e) {
      logError(
        e,
        undefined,
        undefined,
        `uploadImages main: ${images.length} failed`
      );
      errors.push('Failed to upload images');
    }
    setUploadingImage(false);
    return { paths, errors };
  }

  async function deleteImage(
    img: ProductDocument['images'][0],
    currentImages: ProductDocument['images']
  ) {
    const images = currentImages ?? [];
    const newImages = images.filter((image) => image.thumb !== img.thumb);
    // Don't actually delete the image from storage, just remove it from the product. Deleting from storage should be on "on save"
    // try {
    //   await deleteListingImage(img.full, img.thumb);
    // } catch (e) {
    //   // do nothing
    // }
    onDeleteCallback?.(newImages);
  }

  return {
    uploadingImage,
    uploadImages,
    handleFileInputChange,
    deleteImage,
  };
}

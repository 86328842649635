'use client';
import SafeImage from '@ui/SafeImage';
import { getCDNUrl } from '@util/index';
import { BlogDocument } from '@util/types/firestore/blog';
import Link from 'next/link';

export default function PaddockPostCard({ post }: { post: BlogDocument }) {
  return (
    <Link
      href={`/blog/${post.slug}`}
      className="block w-[40rem] cursor-pointer overflow-clip rounded-[3rem] border-[1px] border-zinc-300 transition-all duration-700 ease-brand-section  hover:scale-[1.02] hover:shadow-brand-section lg:w-[43rem]"
    >
      <div className="h-[18rem] w-full overflow-hidden sm:h-[25rem]">
        <SafeImage
          src={getCDNUrl(post.thumbnail)}
          alt={post.title}
          className="h-full w-full  object-cover"
        />
      </div>

      <div className="flex h-[15rem] flex-col  gap-y-4 bg-brand-white p-8">
        <p className="text-[1.8rem] font-medium uppercase text-brand-gray">
          {post.tags[0]}
        </p>

        <p className="text-[1.8rem] font-semibold">{post.title}</p>
      </div>
    </Link>
  );
}

import Link from 'next/link';
import SafeImage from './SafeImage';
import { isMobile } from '@util/index';

const BRANDS = [
  {
    label: 'WP',
    image: 'https://cdn.mxlocker.com/assets/brands/wp-color_1920x2560.png',
    href: '/brands/wp',
  },
  {
    label: 'Guts',
    image: 'https://cdn.mxlocker.com/assets/brands/guts-color_1920x2560.png',
    href: '/brands/guts',
  },
  {
    label: 'FMF',
    image: 'https://cdn.mxlocker.com/assets/brands/fmf-color_1920x2560.png',
    href: '/brands/fmf',
  },
  {
    label: 'Pro Circuit',
    image:
      'https://cdn.mxlocker.com/assets/brands/pro-circuit-color_1920x2560.png',
    href: '/brands/pro-circuit',
  },
  {
    label: 'Specbolt',
    image:
      'https://cdn.mxlocker.com/assets/brands/specbolt-color_1920x2560.png',
    href: '/brands/specbolt',
  },
  {
    label: 'Alpinestars',
    image:
      'https://cdn.mxlocker.com/assets/brands/alpinestars-color_1920x2560.png',
    href: '/brands/alpinestars',
  },
  {
    label: 'Bell',
    image: 'https://cdn.mxlocker.com/assets/brands/bell-color_1920x2560.png',
    href: '/brands/bell',
  },
  {
    label: 'Fox',
    image: 'https://cdn.mxlocker.com/assets/brands/fox-color_1920x2560.png',
    href: '/brands/fox-racing',
  },
  {
    label: '100%',
    image: 'https://cdn.mxlocker.com/assets/brands/100-color_1920x2560.png',
    href: '/brands/100-percent',
  },
  {
    label: 'Aektiv',
    image: 'https://cdn.mxlocker.com/assets/brands/aektiv-color_1920x2560.png',
    href: '/brands/aektiv',
  },
];

const Grid = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid h-full w-full grid-flow-col  grid-rows-2 gap-[1.6rem] overflow-x-scroll pl-8 scrollbar-none sm:auto-rows-auto sm:grid-cols-5 sm:overflow-x-auto sm:pl-0">
      {children}
    </div>
  );
};

const ShopByBrand = () => {
  return (
    <Grid>
      {BRANDS.map((brand, index) => (
        <Link
          key={index}
          href={brand.href}
          className="group relative flex min-w-[28.8rem] overflow-hidden rounded-[2rem] sm:min-w-0"
        >
          <div className="flex items-center justify-center overflow-hidden transition-all duration-150 hover:opacity-100 hover:grayscale-0 lg:grayscale">
            <SafeImage
              src={brand.image}
              alt={brand.label}
              width={1920}
              height={2560}
              className="h-auto w-full object-cover"
            />
          </div>
        </Link>
      ))}
    </Grid>
  );
};

export default ShopByBrand;

import { useQuery } from '@tanstack/react-query';
import { getMyFeed } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';

const useMyFeed = () => {
  const { userDoc } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: ['myFeed', userDoc?.uid],
    queryFn: async () => getMyFeed(userDoc!),
    enabled: !!userDoc,
  });
  return {
    isLoading,
    data: data?.data,
  };
};

export default useMyFeed;

import Carousel from '@ui/Carousel';
import { formatCurrency } from '@util/index';
import { Coupon } from 'models/coupon';
import { PublicUserDocument } from 'models/user';
import Image from 'next/image';
import Link from 'next/link';
import { IoPricetagSharp } from 'react-icons/io5';
const SellerList = ({
  stores,
}: {
  stores?: (PublicUserDocument & { card_bg: string; coupon: Coupon })[];
}) => {
  if (!stores) return null;
  const row1 = stores.slice(0, stores.length / 2);
  const row2 = stores.slice(stores.length / 2, stores.length);
  const items = new Array(Math.max(row1.length, row2.length))
    .fill(0)
    .map((_, i) => (row1[i] ? [row1[i], row2[i]] : null))
    .filter(Boolean);
  return (
    <div className="mx-auto w-screen sm:w-full sm:max-w-default">
      <Carousel
        items={items.map((stack) => {
          if (!stack) return null;
          return (
            <div className="flex flex-col gap-[2rem]">
              <SellerCard store={stack[0]} />
              {stack[1] && <SellerCard store={stack[1]} />}
            </div>
          );
        })}
        emptyMessage="No coupons available"
      />
    </div>
  );
};

const SellerCard = ({
  store,
}: {
  store: PublicUserDocument & { coupon: Coupon; card_bg: string };
}) => {
  return (
    <Link
      href={`/profile/${store.username_slug}`}
      className="group relative flex h-[15rem] w-[27rem] grow overflow-clip rounded-[1.5rem] transition-shadow duration-300 ease-in-out hover:shadow-lg sm:h-[20rem] sm:w-[36rem] sm:hover:shadow-2xl"
    >
      <div className="absolute inset-0 z-10 bg-gradient-to-b from-[#00000066] from-40% to-[#000000e6] to-90%"></div>
      <Image
        src={store.card_bg}
        fill
        alt="card"
        className="object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
      />

      <div className="absolute top-[1rem] z-10 flex w-full flex-col items-center gap-[0.75rem] sm:top-[1.5rem]">
        <Image
          src={store.photo}
          alt="avatar"
          width={256}
          height={256}
          className="h-[3rem] w-[3rem] overflow-clip rounded-full sm:h-[4rem] sm:w-[4rem]"
        />
        <span className="max-w-[20rem] overflow-hidden text-ellipsis whitespace-nowrap font-semibold  uppercase text-white sm:text-[2rem]">
          {store.username}
        </span>
        <div className="rounded-[0.5rem] bg-brand-secondary px-[1rem] font-bold text-white sm:text-[2rem]">
          {store.coupon.percent_off
            ? `${store.coupon.percent_off}% OFF`
            : `${formatCurrency(store.coupon.amount_off!)} OFF`}
        </div>
        <div className="flex gap-[0.5rem] rounded-[0.5rem] bg-white px-[1rem] sm:text-[2rem]">
          Use code
          <span className="flex items-center gap-[0.5rem] font-bold text-brand-secondary">
            <IoPricetagSharp /> {store.coupon.code}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default SellerList;

import Link from 'next/link';
import AppStore from '@i/brands/AppStore.svg';
import GooglePlayStore from '@i/brands/GooglePlayStore.svg';
import Image from 'next/image';

export default function CantDecide() {
  return (
    <div className="w-full bg-[#F1F1F1] p-4 sm:px-default-padding-x sm:pt-16">
      <div className="mx-auto my-8 flex w-full max-w-default flex-col items-center justify-between rounded-2xl bg-brand-white px-16 pt-16 sm:flex-row sm:px-32">
        <div className="flex flex-col justify-between sm:w-1/2 sm:space-y-0">
          <div className="space-y-16">
            <div className="flex flex-col justify-center space-y-2">
              <h1 className="text-5xl font-semibold leading-normal text-black">
                Can&apos;t decide what to buy?{' '}
                <br className="hidden sm:block" /> We&apos;re <i>riders</i> just
                like you.
              </h1>
            </div>

            <p className="w-[38rem] text-3xl font-light">
              Real advice, from real experts. They love what they do and want
              you to love it too.
            </p>

            <div>
              <h2 className="text-4xl font-semibold">
                Connect with an Expert.
              </h2>
            </div>

            <div className="flex items-center gap-x-4">
              <Link
                href="https://apps.apple.com/us/app/mx-locker/id1543819006"
                target="_blank"
                className="rounded-brand border-[1px] border-white"
              >
                <Image src={AppStore} alt="AppStore" className="w-[17rem]" />
              </Link>

              <Link
                href="https://play.google.com/store/apps/details?id=com.mx.locker&pli=1"
                target="_blank"
                className="rounded-brand border-[1px] border-white"
              >
                <Image
                  src={GooglePlayStore}
                  alt="GooglePlayStore"
                  className="w-[17rem]"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="flex h-full w-full justify-end lg:w-1/2">
          <Image
            src={
              'https://storage.googleapis.com/mx-locker-staging.appspot.com/assets/expert%20mockup_1920x2560.png'
            }
            className="h-full w-full sm:h-[52rem] sm:w-auto"
            height={840}
            width={1280}
            alt="MX Expert Mockup"
          />
        </div>
      </div>
    </div>
  );
}

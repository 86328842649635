'use client';
import { useQuery } from '@tanstack/react-query';
import { getFlashDeals } from '@util/firestore/curated/curated.service';
import FlashDeals from 'features/homepage/components/FlashDeals';
import { ProductDocument } from 'models/product';

export default function FlashSales({
  initialData,
}: {
  initialData?: ProductDocument[];
}) {
  const { data: flashDeals, isLoading: flashDealsLoading } = useQuery({
    queryKey: ['flash-deals'],
    queryFn: getFlashDeals,
    initialData: initialData ? initialData : [],
  });
  if (!flashDeals.length) return null;
  
  return (
    <FlashDeals
      title="Flash Deals ⚡"
      allProducts={flashDeals}
      isFetching={flashDealsLoading}
    />
  );
}
